import React from "react"
import Navbar from "../components/navbar"
import { Helmet } from 'react-helmet'
import * as indexStyles from "../styles/css/index.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Marketing from "../styles/images/marketing.png"

export default function Home() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Web Desmot Services, conception et réalisation de site web en Mayenne</title>
        <meta name="title" content="Web Desmot Services, conception et réalisation de site web en Mayenne" />
        <meta name="description" content="Web Desmot Services pour la création de votre site internet. Que ce soit pour un site onepage, un site vitrine, une boutique en ligne ou même une application web, WDS est là pour vous. Nous nous voulons proche de nos clients, c'est pour cela que nous vous accompgnons durant tout votre projet web." />
      </Helmet>

      <Navbar color="orange"/>

      <div className={indexStyles.homeTitleContainer}>
        <h1  className={indexStyles.title}>
          <span>Nous créons des sites internet à votre image.Tout simplement magnifique.</span><br/>
          <span>Trouvons ensemble la solution la plus adaptée à votre projet !</span>
        </h1>
      </div>
      <div className={indexStyles.opposedTextContainer}>
        <div className={indexStyles.opposedTextBlock}>
          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>

          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>

          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>

          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>

          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>

          <span>Design</span>
          <span>Unique</span>
          <span>Créatif</span>
          <span>Impactant</span>
        </div>
        <div className={indexStyles.opposedTextBlock}>
          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>

          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>

          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>

          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>

          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>

          <span>Impactant</span>
          <span>Créatif</span>
          <span>Unique</span>
          <span>Design</span>
        </div>
      </div>
      <div className={indexStyles.container}>
        <div className={indexStyles.missionContainer}>
          <h2 className={indexStyles.titleSection}>
            <span>Notre mission ?</span> Vous rendre présent sur le web
          </h2>
          <p>
          Aujourd’hui, être présent sur internet est important voir même indispensable. C’est le moment, plus que jamais, de sauter le pas et vous rendre visible aux yeux du monde. Que vous souhaitez présenter votre activité, vendre des produits ou même créer une application sur-mesure pour augementer votre productivité, le web aura la solution… Nous aurons la solution ! Alors n’hésiter plus, contactez-nous et lancez-vous sur le web !
          </p>
        </div>
        <div className={indexStyles.ellipseContainer}>
          <div className={indexStyles.ellipseFront}>
            <p>
              Afin d'exploiter le potentiel du web, nous développons des sites internet qui vous ressemblent et qui vous représentent.
            </p>
          </div>
          <div className={indexStyles.ellipseBack}></div>
        </div>
      </div>
      <div className={indexStyles.containerServices}>
        <div className={indexStyles.servicesPrez}>
          <h2>Nos incroyables <span>services</span></h2>
          <p>
          Nos compétences techniques dans le domaine du web nous permettent de vous proposer une gamme de services complète, allant de la réalisation d'un site vitrine à la réalisation d'une application web, en passant bien sûr par la réalisation d'un boutique en ligne.
          </p>
          <div className={indexStyles.btnHover}>
            <AniLink paintDrip color="purple" to="/services/" className={indexStyles.btn} >En savoir plus</AniLink>
          </div>
        </div>
        <div className={indexStyles.containerCard}>
          <div className={indexStyles.serviceCard} id="homeVitrine">
          <div className={indexStyles.serviceCircle}></div>
            <h3>Site vitrine</h3>
            <p>
            Envie de présenter l’activité et les services de votre entreprise ? Ce type de site est parfait pour vous !
            </p>
          </div>
          <div className={indexStyles.serviceCard} id="homeEcommerce">
          <div className={indexStyles.serviceCircleBlack}></div>
            <h3>Boutique en ligne</h3>
            <p>
            Envie de créer une boutique en ligne ? Nous donnons vie à votre idée !
            </p>
          </div>
          <div className={indexStyles.serviceCard} id="homeAppli">
            <div className={indexStyles.serviceCirclePurple}></div>
            <h3>Application web</h3>
            <p>
            Une idée d’application web ? Contactez-nous pour discuter de la solution adaptée à vos besoins.
            </p>
          </div>
        </div>
      </div>
      <div className={indexStyles.containerProjet}>
        <div className={indexStyles.containerTextProjet}>
          <h2 className={indexStyles.titleSection}>Ensemble réalisons <span>votre projet</span></h2>
          <p className={indexStyles.subTitleSection}>Lancez-vous à la conquête du web !</p>
          <p>WDS vous accompagne tout au long de votre projet web, de la conception à la réalisation, vous aurez toujours quelqu’un à votre écoute. La principale préoccupation de WDS, c’est vous ! Notre souhait est de vous livrer le projet web qui vous correspond et qui vous satisfait à 100% !</p>
        </div>
        <div className={indexStyles.containerImg}>
        <img className={indexStyles.imgProject} src={Marketing}></img>
        </div>
      </div>
      <div className={indexStyles.containerEtapes}>
        <h2 className={indexStyles.titleSection}><span>Parcours d'un projet</span> avec nous</h2>
        <p className={indexStyles.subTitleSection}>Nous vous accompagnons tout au long de votre projet</p>
        <div className={indexStyles.etapesContainer}>
          <div className={indexStyles.etapesLine}>
            <div className={indexStyles.etape}>
              <h3>
                <span className={indexStyles.etapeTitle}>Compréhension</span>
                <span className={indexStyles.etapeSubTitle}>1ère étape</span>
              </h3>
              <p>
              La première étape d’un projet et surement la plus importante est la phase d’analyse. Au gré d’une rencontre nous analyserons vos besoins afin de vous proposer la solution idéale.
              </p>
            </div>
            <div className={indexStyles.etape}>
              <h3>
                <span className={indexStyles.etapeTitle}>Conception</span>
                <span className={indexStyles.etapeSubTitle}>2ème étape</span>
              </h3>
              <p>
                Chaque projet étant unique, une fois vos attentes bien définies, nous vous proposons une solution adaptée à vos besoins.
              </p>
            </div>
          </div>
          <div className={indexStyles.etapesLine}>
            <div className={indexStyles.etape} id="etapeTrois">
              <h3>
                <span className={indexStyles.etapeTitle}>Réalisation</span>
                <span className={indexStyles.etapeSubTitle}>3ème étape</span>
              </h3>
              <p>
              Après avoir validé la solution idéale pour votre projet, il est venu l’heure de la réalisation de celui-ci.
              </p>
            </div>
            <div className={indexStyles.etape}
            onMouseEnter={() =>{
              document.getElementById("etapeTrois").classList.add("etapeMini")
          }} 
            onMouseLeave={() =>{
              document.getElementById("etapeTrois").classList.remove("etapeMini")
          }}
            >
              <h3>
                <span className={indexStyles.etapeTitle}>Accompagnement</span>
                <span className={indexStyles.etapeSubTitle}>4ème étape</span>
              </h3>
              <p>
              Et voilà le projet est terminé ! Que se passe-t-il maintenant ? Et bien nous continuons notre accompagnement pour que votre projet perdure dans le temps.
              </p>
            </div>
          </div>
        </div>
        <div className={indexStyles.containerContact}>
          <h2 className={indexStyles.titleContact}>Contactez-nous</h2>
          <p className={indexStyles.subTitleContact}>Une idée ? Un projet ? Contrésisez-le !</p>
          <div className={indexStyles.contactAction}>
            <div className={indexStyles.btnHoverEmail}>
              <a href="mailto:contact@agencewds.fr" className={indexStyles.btn}>Envoyer un email</a>
            </div>
            <div className={indexStyles.btnHoverCall}>
              <a href="tel:0633739441" className={indexStyles.btn}>Appeler</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  
}
