// extracted by mini-css-extract-plugin
export const homeTitleContainer = "index-module--homeTitleContainer--1MA6r";
export const title = "index-module--title--EP-Ka";
export const opposedTextContainer = "index-module--opposedTextContainer--2uE5I";
export const opposedTextBlock = "index-module--opposedTextBlock--1fwod";
export const slideleft = "index-module--slideleft--1y-SZ";
export const slideright = "index-module--slideright--2LEWR";
export const container = "index-module--container--P0QF2";
export const missionContainer = "index-module--missionContainer--1vTR_";
export const titleSection = "index-module--titleSection--O_-CO";
export const ellipseContainer = "index-module--ellipseContainer--3auPe";
export const ellipseFront = "index-module--ellipseFront--3g5if";
export const ellipseBack = "index-module--ellipseBack--UeOsP";
export const containerProjet = "index-module--containerProjet--3sBV5";
export const containerTextProjet = "index-module--containerTextProjet--hFur3";
export const subTitleSection = "index-module--subTitleSection--2rNI6";
export const containerImg = "index-module--containerImg--1Ve80";
export const imgProject = "index-module--imgProject--b3m-e";
export const etapesContainer = "index-module--etapesContainer--3RpU_";
export const etapesLine = "index-module--etapesLine--28QST";
export const etape = "index-module--etape--1gtTL";
export const etapeTitle = "index-module--etapeTitle--350Ic";
export const etapeSubTitle = "index-module--etapeSubTitle--1m3_n";
export const containerEtapes = "index-module--containerEtapes--1bx8X";
export const containerServices = "index-module--containerServices--2o3Kc";
export const containerCard = "index-module--containerCard--1XDv_";
export const servicesPrez = "index-module--servicesPrez--3yPYx";
export const serviceCard = "index-module--serviceCard--3NLjS";
export const serviceCircle = "index-module--serviceCircle--23gqc";
export const serviceCircleBlack = "index-module--serviceCircleBlack--3T5P8";
export const serviceCirclePurple = "index-module--serviceCirclePurple--ET3ut";
export const btn = "index-module--btn--OtqtF";
export const btnHover = "index-module--btnHover--ppcGT";
export const containerContact = "index-module--containerContact--3FXMj";
export const titleContact = "index-module--titleContact---af9B";
export const subTitleContact = "index-module--subTitleContact--2oVhj";
export const btnHoverEmail = "index-module--btnHoverEmail--3p2Vt";
export const btnHoverCall = "index-module--btnHoverCall--1I1SC";
export const contactAction = "index-module--contactAction--3e6HJ";
export const maintenanceContainer = "index-module--maintenanceContainer--3W3I2";
export const maintenanceTitle = "index-module--maintenanceTitle--MjLtm";
export const maintenanceContact = "index-module--maintenanceContact--TMvlv";